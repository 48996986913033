import Vue from "vue";
import Alertize from "@/components/Alertize.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardActions from "@/components/Content/CardAction.vue";
import DialogDefault from "@/components/Content/Dialogs/Default.vue";

import {
	isRequired,
	isNumber,
	isDomain,
	isMinLength,
	isMaxLength,
	isMaxCustomLength,
} from "@/services/rule-services";
import { TypeLoading } from "@/interfaces/loading";
import { mapActions, mapGetters } from "vuex";
import { isEmpty, isNull } from "lodash";
import { getPermisionPlanning } from "@/utils/permissionResolve";
import { FileResponseEntity } from "@/models/Upload";
import { PrivatePoisCreatedEntity, PrivatePoisFormEntity, PrivatePoisResourceEntity, PrivatePoisRulesEntity } from "@/models/persons/v12/PrivatePois";

const COUNTRY_TYPE = "countries";

export default Vue.extend({
	name: "PrivatePoisForm",
	props: {},
	components: {
		Alertize,
		CardTextField,
		CardAutocomplete,
		CardActions,
		DialogDefault,
	},
	data: () => ({
		valid: true,
		form: new PrivatePoisFormEntity(),
		rules: new PrivatePoisRulesEntity(),
		resource: new PrivatePoisResourceEntity(),
		file: new FileResponseEntity(),
		validationRules: [],
		validationInfoGeo: [],
		validationContentInfoGeo: [],
		open: {
			fileValidate: false,
		},
	}),
	created() {
		this.$nextTick(async () => {});
	},
	mounted() {
		this.$nextTick(async () => {
			await this.fetchDataCountry()
			.catch(async (err) =>{
				await this.setLoadingData();
			});
		});
	},
	computed: {
		...mapGetters("profile", ["getAbility"]),
		getRules() {
			return {
				isRequired,
				isNumber,
				isDomain,
				isMinLength,
				isMaxLength,
				isMaxCustomLength,
			};
		},

		getPermission(){
			return getPermisionPlanning();
		},

		canCreate(){
			return this.getAbility.can(this.getPermission.actions.index, this.getPermission.subject);
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("privatePois", [
			"fetchCountry",
			"createPrivatePOIs",
			"setCreated",
		]),

		async loadValidationRules() {
			for (let index = 1; index < 7; index++) {
				this.validationRules.push(
					`privatepois.validate.validFileItem${index}`
				);
			}
		},
		async loadValidationInfoGeo() {
			for (let index = 1; index < 4; index++) {
				this.validationInfoGeo.push(
					`privatepois.validateType.infoGeoItems.infoGeoItem${index}`
				);
			}
		},
		async loadValidationContentInfoGeo() {
			for (let index = 1; index < 6; index++) {
				this.validationContentInfoGeo.push(
					`privatepois.validateType.infoContentFileItems.infoContentFileItem${index}`
				);
			}
		},
		async fetchDataCountry() {
			await this.setLoadingData(true);
			const countries: any[] = await this.fetchCountry({
				type: COUNTRY_TYPE,
			});
			this.resource.setResource("country_code", countries);
			await this.setLoadingData();
		},
		async addRules(reset: Boolean = false) {
			const rules: any[] = reset ? [] : [this.getRules.isRequired];
			this.rules.addRules("name_layer", rules);
			this.rules.addRules("country_code", rules);
			this.rules.addRules("file", rules);
		},
		async validate() {
			return await this.$refs.form.validate();
		},
		async handleSubmit() {
			try {
				await this.addRules(); // add rules
				if (!(await this.validate())) return;
				await this.setLoadingData(TypeLoading.loading);
				const payload: FormData = await this.createFormData();
				const created: PrivatePoisCreatedEntity =
					await this.createPrivatePOIs(payload);
				await this.setCreated(created);

				this.resetResources(); // reset data
				await this.setLoadingData();
			} catch (error) {
				console.error("handleSubmit", { error: error });
				await this.setLoadingData();
			}
		},
		async handleAction(action: any) {
			switch (action.type) {
				case "cancel":
					this.handleCancel();
					break;
			}
		},
		async handleUpload(event: any) {
			if (!isNull(event)) {
				(this.file as FileResponseEntity).setData({
					uploaded: true,
					isValid: true,
					messages: [],
				});
			}
		},
		handleUploadClear() {
			this.file = new FileResponseEntity();
		},
		async showModalValidFile(params: Boolean) {
			if (isEmpty(this.validationRules)) {
				await this.loadValidationRules();
				await this.loadValidationInfoGeo();
				await this.loadValidationContentInfoGeo();
			}
			this.open.fileValidate = params;
		},
		downloadTemplate(archivoCsv:string) {
			window.location.href = `/templates/${archivoCsv}`;
		},
		async createFormData() {
			let formData = new FormData();
			formData.append("country_code", this.form.country_code);
			formData.append("layer_name", this.form.name_layer);
			formData.append("file", this.form.file);
			return formData;
		},
		resetResources() {
			this.form = new PrivatePoisFormEntity();
			this.rules = new PrivatePoisRulesEntity();
			this.file = new FileResponseEntity();
		},
	},
	watch: {},
});
